@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportLarge) {
    position: sticky;
    top: 0;
    left: 0;
    z-index: var(--zIndexTopbar);
  }
}

.container {
  width: 100%;
  flex-grow: 1;
  display: flex;

  @media (--viewportMedium) {
    position: relative;
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

/* Container for side and content */
.layoutWrapperContainer {
  composes: container;
  /* parent aka root is flexbox, this container takes all available space */
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--matterColorBright);
  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  /* z-index: calc(var(--zIndexTopbar) - 1); */

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperFilterColumn {
  display: none;

  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 0 0 24px;
  }

  @media (--viewportLarge) {
    /* Layout */
    padding: 40px 0 0 36px;

    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (min-width: 1440px) {
    padding: 40px 0 0 calc((100vw - 1368px) / 2);
  }

  @media (--viewportXLarge) {
    padding: 40px 0 0 calc((100vw - 1800px) / 2);
  }
}

.filterColumnContent {
  width: 210px;
  padding: 8px 24px 0 0;

  @media (--viewportMedium) {
    padding: 8px 24px 24px 0;
  }

  @media (--viewportLarge) {
    width: 240px;
    padding: 8px 36px 24px 0;
  }

  @media (min-width: 1440px) {
    padding: 8px 60px 24px 0;
  }
}

.allFilters {
  & .filter {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: solid 1px var(--matterColorNegative);

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

.filterFooter {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--matterColorLight);
  padding: 15px 24px;
  border: none;
  border-top: solid 1px var(--matterColorNegative);
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resetAllFiltersButton,
.resetAllButton {
  composes: marketplaceH5FontStyles from global;
  color: var(--matterColorDark);
  display: inline;
  padding: 0;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  font-weight: var(--fontWeightSemiBold);
  outline: none;
  text-align: left;
  cursor: pointer;
  text-decoration: underline;
  border: none;
  transition: all ease 0.5s;

  @media (--viewportSmall) {
    font-size: 16px;
  }

  &:focus,
  &:hover {
    transition: all ease 0.5s;
    color: var(--marketplaceColor);
  }
}

.showResults {
  composes: marketplaceH5FontStyles from global;
  background-color: var(--matterColorDark);
  color: var(--matterColorLight);
  display: inline;
  padding: 0;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  font-weight: var(--fontWeightSemiBold);
  outline: none;
  text-align: left;
  cursor: pointer;
  border: none;
  padding: 10px 15px;
  transition: all ease 0.5s;

  @media (--viewportSmall) {
    padding: 10px 20px;
    font-size: 16px;
  }

  &:focus,
  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
    transition: all ease 0.5s;
  }
}

.resetAllFiltersButton {
  color: var(--marketplaceColor);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 15px 15px 15px 15px;
  max-width: calc(1200px + 30px);
  margin: 0 auto;
  width: 100%;

  @media (--viewportMedium) {
    padding: 14px 24px 24px 24px;
    max-width: calc(1200px + 48px);
  }

  @media (--viewportLarge) {
    padding: 0px 24px 24px 24px;
  }
}

.noSearchResults {
  composes: marketplaceH4FontStyles from global;
  text-align: center;
  padding-top: 24px;
  font-size: large;
  font-weight: 500;
  /* Reserves 48px of vertical space */
  line-height: 24px;
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.mainPanel {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    position: sticky;
    top: 78px;
    background-color: #fcfcfc;
    z-index: 1;
    padding: 14px 0;
  }
}

.mainPanelMapVariant {
  composes: mainPanel;

  @media (--viewportMedium) {
    padding: 18px 24px 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 23px 36px 0 36px;
  }
}

.searchFiltersMobileMap {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersMobileList {
  padding: 0px 0 16px 0;

  @media (--viewportLarge) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.listingsForMapVariant {
  composes: listings;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
  position: relative;
  z-index: 0;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
}

.sortByDesktop {
  height: 45px;
}

.sortByMenuLabel {
  composes: marketplaceButtonStylesSecondary from global;
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  height: 35px;
  width: 35px;
  min-height: 35px;
  padding: 0px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
  border-radius: 100px !important;

  @media (--viewportLarge) {
    width: auto;
    border-radius: 10px !important;
    padding: 0 18px;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}

.bottomNavbar {
  display: none;
  justify-content: space-around;
  position: fixed;
  border-radius: 10px;
  margin: 0 auto;
  width: 95%;
  left: 0;
  right: 0;
  bottom: 12px;
  background-color: #fff;
  z-index: 10;
  border-top: solid 1px #ddd;
  /* box-shadow: 0px 0px 40px #c9c9c9; */
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;

  @media (max-width: 767px) {
    display: flex;
    padding: 11px 2px 17px 2px;
  }

  & > a {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #000;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 10px 10px 10px;
    /* opacity: 0.6; */

    @media (max-width: 480px) {
      padding: 8px 8px 0px;
      font-size: 13px;
    }

    @media (max-width: 375px) {
      font-size: 11px;
    }

    &.active {
      opacity: 1;
    }

    & > svg,
    & > img {
      width: 40px;
      margin-bottom: 1px;
      margin-top: 1px;

      @media (max-width: 480px) {
        width: 30px;
      }
    }
  }
}

.navLink {
  cursor: pointer;
  position: relative;

  &:hover {
    text-decoration: none;
  }

  & .inbox {
    color: #333;
    text-decoration: none;

    &:hover {
      color: var(--marketplaceColor);
    }
  }

  & .badge {
    background-color: #c6488c;
    color: var(--matterColorLight);
    font-size: 10px;
    line-height: 16px;
    border-radius: 100px;
    width: 15px;
    height: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 4px;
    right: 3px;
  }

  &:first-child {
    margin-left: 0;
  }

  & > svg {
    color: #333;

    &:hover {
      color: var(--marketplaceColor);
    }

    &.cartIcon {
      width: 25px;
    }
  }
}
