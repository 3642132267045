@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.searchOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 24px; */

  @media (--viewportLarge) {
    flex-direction: row;
    padding: 2px 0px;
  }
}

.filters {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    padding: 0 40px;
  }

  @media (--viewportLarge) {
    width: calc(100% - 540px);
  }

  & .itemSlider {
    width: 100%;

    & .sliderItem {
    }
  }

  & .sliderControls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--marketplaceColor) !important;
    color: var(--matterColorLight) !important;
    padding: 0;
    margin: 0;
    height: 3rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (--viewportMedium) {
      height: 3rem;
      width: 3rem;
    }

    @media (--viewportLarge) {
      height: 3rem;
      width: 6rem;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;

      & > svg {
        & > path {
          fill: var(--matterColorLight);
        }
      }
    }

    &.leftArrow {
      left: 10px;
      background-color: var(--matterColorAnti) !important;
      width: 24px;
      height: 24px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      border-radius: 100px !important;
      margin: 0 !important;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: var(--marketplaceColor) !important;
      }

      & > svg {
        width: 14px;
        height: 11px;
      }
    }

    &.rightArrow {
      right: 10px;
      background-color: var(--matterColorAnti) !important;
      width: 24px;
      height: 24px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      border-radius: 100px !important;
      margin: 0 !important;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: var(--marketplaceColor) !important;
      }

      & > svg {
        width: 14px;
        height: 11px;
      }
    }
  }

  & :global(.react-multi-carousel-list) {
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: initial;
  }

  & :global(.react-multi-carousel-track) {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    will-change: transform, transition;
  }

  & .link {
    text-decoration: none;
    position: relative;
    transition: all ease 0.5s;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    &::after {
      content: '';
      bottom: 0px;
      left: 0;
      right: 0;
      width: 0;
      margin: 0 auto;
      position: absolute;
      background-color: var(--matterColorAnti);
      height: 2px;
      transition: all ease 0.5s;
    }

    &:hover {
      transition: all ease 0.5s;

      &::after {
        width: 80%;
        transition: all ease 0.5s;
        background-color: var(--matterColorDark);
      }

      & .quickFilter {
        & span {
          color: var(--matterColorDark);
        }

        & > svg {
          width: 24px;
          height: 24px;
          & path {
            fill: var(--matterColorDark);
          }

          & polygon {
            transition: all ease 0.5s;
            fill: var(--matterColorDark);
          }
        }
      }
    }

    &.active {
      transition: all ease 0.5s;

      &::after {
        width: 80%;
        background-color: var(--matterColorDark);
        transition: all ease 0.5s;
      }

      & .quickFilter {
        transition: all ease 0.5s;

        & span {
          transition: all ease 0.5s;
          color: var(--matterColorDark);
        }

        & > svg {
          width: 24px;
          height: 24px;
          transition: all ease 0.5s;

          & path {
            transition: all ease 0.5s;
            fill: var(--matterColorDark);
          }

          & polygon {
            transition: all ease 0.5s;
            fill: var(--matterColorDark);
          }
        }
      }
    }

    & .quickFilter {
      transition: all ease 0.5s;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;

      & img {
        width: 24px;
        margin-bottom: 5px;
      }

      & > svg {
        width: 24px;
        height: 24px;
        margin-bottom: 5px;
        transition: all ease 0.5s;

        & path {
          transition: all ease 0.5s;
          fill: var(--matterColorAnti);
        }

        & polygon {
          transition: all ease 0.5s;
          fill: var(--matterColorAnti);
        }
      }

      & span {
        color: var(--matterColorAnti);
        font-size: 13px;
        line-height: 116%;
        transition: all ease 0.5s;
        text-align: center;
      }
    }
  }
}

.sortyByWrapper {
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;

  @media (--viewportMLarge) {
    width: auto;
  }

  & .filterSearchBox {
    margin-left: 10px;
  }

  & .rightFilters {
    display: flex;
    align-items: center;

    & > * {
      margin-left: 10px;
    }
  }
}

.searchResultSummary {
  composes: marketplaceH4FontStyles from global;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.loadingResults {
  composes: marketplaceH4FontStyles from global;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  white-space: nowrap;
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightRegular);
  padding: 8px 8px 8px 0;
}

.moreFilters {
  border: solid 1px var(--matterColorNegative);
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 4px 12px;
  height: 45px;
  color: var(--matterColorDark);
  cursor: pointer;
  transition: all ease 0.5s;
  position: relative;

  &:hover {
    transition: all ease 0.5s;
    color: var(--matterColorDark);
    border-color: var(--matterColorAnti);
    box-shadow: var(--boxShadowButton);

    & > svg {
      transition: all ease 0.5s;

      & > path {
        transition: all ease 0.5s;
        stroke: var(--matterColorDark);
      }
    }
  }
  & .count {
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    font-size: 13px;
    color: var(--matterColorLight);
    font-weight: var(--fontWeightRegular);
    position: absolute;
    top: -6px;
    right: -6px;
    background-color: var(--marketplaceColor);
  }
  & > svg {
    transition: all ease 0.5s;
    margin-right: 5px;
    height: 18px;
    width: 18px;

    & > path {
      transition: all ease 0.5s;
      stroke: var(--matterColorDark);
    }
  }
}

.clearFilters {
  border: solid 1px var(--matterColorNegative);
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 4px 12px;
  height: 45px;
  color: var(--matterColorAnti);
  cursor: pointer;
  transition: all ease 0.5s;

  &:hover {
    transition: all ease 0.5s;
    color: var(--matterColorAnti);
    border-color: var(--matterColorAnti);
    box-shadow: var(--boxShadowButton);

    & > svg {
      transition: all ease 0.5s;

      & > path {
        transition: all ease 0.5s;
        fill: var(--matterColorAnti);
      }
    }
  }

  & > svg {
    transition: all ease 0.5s;
    margin-right: 5px;
    height: 18px;
    width: 18px;

    & path {
      transition: all ease 0.5s;
      fill: var(--matterColorAnti);
    }
  }
}

.active {
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 4px 12px;
  height: 45px;
  color: var(--matterColorDark);
  border-color: var(--matterColorDark);
  cursor: pointer;
  transition: all ease 0.5s;

  &:hover {
    transition: all ease 0.5s;
    color: var(--matterColorDark);
    border-color: var(--matterColorAnti);

    & > svg {
      transition: all ease 0.5s;

      & > path {
        transition: all ease 0.5s;
        fill: var(--matterColorDark);
      }
    }
  }

  & > svg {
    transition: all ease 0.5s;
    margin-right: 5px;
    height: 18px;
    width: 18px;

    & path {
      transition: all ease 0.5s;
      fill: var(--matterColorDark);
    }
  }
}

.mobileFilterCategories {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & .link {
    min-width: 100px;
  }
}
