@import '../../../styles/customMediaQueries.css';

.root {
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportMedium) {
    border-bottom: 0;
  }
}

.filterHeader {
  line-height: 24px;
}

.label,
.labelSelected {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 8px;
    font-size: 20px;
  }
}

.label {
  color: var(--matterColorDark);
}

.labelSelected {
  color: var(--matterColorDark);
}

.openSign {
  float: right;
}

.labelButton {
  /* Override button styles */
  width: 100%;
  outline: none;
  line-height: 24px;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);

    & * {
      stroke: var(--marketplaceColor);
    }
  }
}

.labelButtonContent {
  display: inline-block;
  width: 100%;
  /* margin-bottom: 14px; */
}

.optionsContainerOpen {
  height: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > .option {
    width: calc(100% / 2 - 20px);
    padding: 15px;
    display: flex;
    flex-direction: column;
    border: solid 1px var(--matterColorNegative);
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
    transition: all ease 0.5s;
    font-size: 14px;

    &:nth-child(2n) {
      margin-right: 0;
    }

    @media (--viewportSmall) {
      width: calc(100% / 3 - 20px);

      &:nth-child(2n) {
        margin-right: 20px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @media (--viewportMedium) {
      width: calc(100% / 4 - 20px);
      font-size: 16px;
      padding: 20px;

      &:nth-child(2n) {
        margin-right: 20px;
      }

      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    &.optionHighlight,
    &:hover {
      background-color: var(--matterColorBright);
      border: solid 1px var(--matterColorDark);
      transition: all ease 0.5s;
      color: var(--matterColorDark);
    }

    & > img {
      width: 30px;
      margin-bottom: 10px;

      @media (--viewportMedium) {
        width: 40px;
      }
    }
  }
}

.optionsContainerClosed {
  height: 0;
  overflow: hidden;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.optionBorder,
.optionBorderSelected {
  position: absolute;
  height: calc(100% - 12px);
  top: 4px;
  left: -24px;
  transition: width var(--transitionStyleButton);

  @media (--viewportMedium) {
    height: calc(100% - 8px);
    top: 8px;
  }
}

/* left animated "border" like hover element */
.optionBorder {
  width: 0;
  background-color: var(--matterColorDark);
}

/* left static border for selected element */
.optionBorderSelected {
  width: 8px;
  background-color: var(--matterColorDark);
}

.option {
  composes: marketplaceH4FontStyles from global;
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  padding: 0;
  border: 1px solid #dedede;
  color: var(--matterColor);
  text-align: left;
  display: block;
  position: relative;
  margin: 0;
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }
}

.optionSelected {
  composes: option;
  color: var(--matterColorDark);
}

.clearButton {
  composes: marketplaceH5FontStyles from global;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);
  margin: 20px auto 0 0;
  padding: 0;

  text-decoration: underline;
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
  }
}

.tutulistEncore {
  display: flex;
  gap: 10px;
  cursor: pointer;
  margin-left: 4px;

  & input {
    cursor: pointer;
    width: 17px !important;
    height: 24px;
    position: relative;
    top: 10px;
    border: 1px solid var(--matterColorNegative);
  }

  & label {
    cursor: pointer;
    position: relative;
    top: 11px;
  }
}
